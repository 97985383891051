import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

import PageHeading from "../components/page_heading";
import ToC from "../components/toc";
import SideBar from "../components/sidebar";
import {
  TagCloud2,
  ItemLinks,
  DivStyled,
  BlogHtmlStyler,
} from "../components/pages/blog-template";
import CodeBlock from "../components/CodeBlock";

const BlogPost = ({ data }) => {
  let gatsbyImgData = undefined;
  if (data.mdx.frontmatter.myimage) {
    gatsbyImgData =
      data.mdx.frontmatter.myimage.childImageSharp.gatsbyImageData;
  }
  const toc = <ToC headings={data.mdx.headings} className="foo"></ToC>;

  const frontmatter = data.mdx.frontmatter;
  const tagNames = frontmatter.tags ? frontmatter.tags.split(" ") : undefined;
  const githubLink = frontmatter.github_link;
  const pdfLink = frontmatter.pdf_link;
  const websiteLink = frontmatter.website_link;
  const linkElements = (
    <ItemLinks
      githubLink={githubLink}
      pdfLink={pdfLink}
      websiteLink={websiteLink}
    />
  );
  const components = {
    pre: CodeBlock,
  };
  return (
    <>
      {/* TODO: find why we can't pass toc as a child element */}
      <SideBar elements={toc} />
      <Layout iconName="blog">
        {/* <Layout iconName="blog" heroSrc={image_src}> */}
        <DivStyled>
          <PageHeading
            blogHeading
            headingText={data.mdx.frontmatter.title}
            subHeadingText={data.mdx.frontmatter.date}
            heroGatsbyData={gatsbyImgData}
          />
          <TagCloud2 tagNames={tagNames} />

          {linkElements}
          <BlogHtmlStyler>
            <MDXProvider components={components}>
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </MDXProvider>
          </BlogHtmlStyler>
        </DivStyled>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      id
      headings {
        value
        depth
      }
      slug
      body
      frontmatter {
        date
        github_link
        pdf_link
        website_link
        tags
        title
        myimage {
          absolutePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default BlogPost;
