import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";
import Prism from "prism-react-renderer/prism";
import styled from "styled-components";

const Line = styled.div`
  display: table-row;
`;

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

const LineContent = styled.span`
  display: table-cell;
  @media screen and (max-width: 480px) {
    .token-line {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

const CodeWrapper = styled.div`
  .prism-code {
    border-radius: 1em;
  }
`;

export default (props) => {
  const className = props.children.props.className || "";
  const matches = className.match(/language-(?<lang>.*)/);

  return (
    <CodeWrapper>
      <Highlight
        {...defaultProps}
        code={props.children.props.children.trim()}
        language={
          matches && matches.groups && matches.groups.lang
            ? matches.groups.lang
            : ""
        }
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={{ ...style, padding: "20px" }}>
            {tokens.map((line, i) => (
              <Line key={i} {...getLineProps({ line, key: i })}>
                <LineNo>{i + 1}</LineNo>
                <LineContent>
                  <div key={i} {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                </LineContent>
              </Line>
            ))}
          </pre>
        )}
      </Highlight>
    </CodeWrapper>
  );
};
