import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { BiLinkExternal } from "react-icons/bi";
import { BsFillFilePdfFill } from "react-icons/bs";
import styled from "styled-components";
import { TagCloudBox } from "../searchbar";
import { Tag } from "../thumbnail";

const DivStyled = styled.div`
  .head-image-container {
    z-index: 1;
    width: 100%;
  }
  .bgrnd {
    width: 100%;
    height: 100%;
  }
`;

const TagCloud2 = ({ tagNames }) => {
  if (tagNames === undefined) {
    return <></>;
  } else {
    const tagsItems = tagNames.map((textTag, index) => (
      <Tag key={textTag} textTag={textTag} className="clicked">
        #{textTag}
      </Tag>
    ));
    return <TagCloudBox>{tagsItems}</TagCloudBox>;
  }
};

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0.2em;
  text-align: center;
`;

const LinkItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  /* align-items: center; */
  font-size: 3em;
  vertical-align: middle;
`;

const ItemLinks = ({ githubLink, pdfLink, websiteLink }) => {
  const githubElem = githubLink ? (
    <a href={githubLink}>
      <StyledIcon icon={faGithub} className="icon" />
    </a>
  ) : (
    <></>
  );
  const pdfElem = pdfLink ? (
    <a href={pdfLink}>
      <BsFillFilePdfFill className="icon" />
    </a>
  ) : (
    <></>
  );
  const websiteElem = websiteLink ? (
    <a href={websiteLink}>
      <BiLinkExternal className="icon" />
    </a>
  ) : (
    <></>
  );
  return (
    <LinkItemContainer>
      {githubElem}
      {pdfElem}
      {websiteElem}
    </LinkItemContainer>
  );
};

const BlogHtmlStyler = styled.div`
  iframe {
    max-width: 100% !important;
    width: 100% !important;
    aspect-ratio: 1.68/1 !important;
  }
`;

export { TagCloud2, ItemLinks, DivStyled, BlogHtmlStyler };
